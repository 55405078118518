<template>
  <div class="myAssets">
    <div class="readcrumb nav-title"></div>
    <div class="top">
      <div class="balance">
        <p class="nums"><i class="hy-icon-jiage"></i>账号余额</p>
        <p style="margin-top: 10px" v-if="monynum > 0 || monynum == 0">
          <span>中信账户余额:</span><span style="color: red">￥</span
          ><span style="color: red">{{ monynum }}</span>
        </p>
        <p style="margin-top: 10px" v-if="monynums > 0 || monynums == 0">
          <span>中金账户余额:</span><span style="color: red">￥</span
          ><span style="color: red">{{ monynums }}</span>
        </p>
      </div>
      <div>
        <el-button
          type="primary"
          style="margin: 10px"
          @click="AccountBalanceByParam('1')"
          >刷新金额</el-button
        >
      </div>
      <!-- <div class="right">
        <el-button
          class="btn "
          :class="{'btn-active': btnndex == 1 }"
          @click="recordbtn(1)"
          >充值记录</el-button
        >
        <el-button
          class="btn"
          :class="{ 'btn-active': btnndex == 2 }"
          @click="recordbtn(2)"
          >提现记录</el-button
        >
      </div> -->
    </div>
    <div class="bottom">
      <div class="nav">
        <p
          class="nav-item"
          :class="{ active: activeName == 1 }"
          @click="nav(1)"
        >
          充值
          <span v-if="activeName == 1"></span>
        </p>
        <p
          class="nav-item"
          :class="{ active: activeName == 2 }"
          @click="nav(2)"
        >
          提现
          <span v-if="activeName == 2"></span>
        </p>
      </div>
      <div v-if="activeName == 1">
        <div style="margin-left: 10px">
          <el-radio
            v-model="radio"
            label="1"
            v-if="monynums > 0 || monynums == 0"
            >中金账户充值</el-radio
          >
          <el-radio v-model="radio" label="0" v-if="monynum > 0 || monynum == 0"
            >中信账户充值</el-radio
          >
        </div>

        <div v-if="radio == 1" style="margin-left: 10px; margin-top: 10px">
          <div>充值方式：网银支付</div>
          <div class="flex" style="margin-top: 10px">
            <div>充值金额</div>
            <div style="margin-left: 10px">
              <el-input v-model="input" placeholder="请输入充值金额"></el-input>
            </div>
          </div>
          <div class="flex" style="margin-top: 10px">
            <div>充值银行</div>
            <div style="margin-left: 10px">
              <el-select v-model="bankID" filterable placeholder="请选择" @visible-change="bankChange">
                <el-option
                  v-for="item in options"
                  :key="item.bankId"
                  :label="item.bankName"
                  :value="item.bankId"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <div style="text-align: center; margin-top: 20px">
            <el-button
              type="primary"
              @click="AddRechargeOnlineForZJ"
              v-loading.fullscreen.lock="fullscreenLoading"
              >提交充值</el-button
            >
          </div>
        </div>
        <div style="padding: 20px" v-if="radio == 0">
          <div>第一步：查看平台账户余额</div>
          <div class="zhi_img">
            <img src="../../../assets/zxx_img/11.png" alt="" />
          </div>
          <div>第二步：选择充值渠道：</div>
          <div style="margin: 10px 0px">
            您可以选择通过网上银行、银联等平台，通过转账功能，在填入在第一步中查到的专属账户(请核对账户输入无误),开户行：中信银行股份有限公司乌鲁木齐分行营业部，填写转入金额，完成转账操作则充值完成。
          </div>
          <div>第三步：查询余额： 点击“刷新”，即可查看到余额信息</div>
          <div class="zhi_img">
            <img src="../../../assets/zxx_img/22.png" alt="" />
          </div>
          <div style="color: red">
            在交易记录内可查看您的充值记录(充值记录结果返回与银行同步，可能无法实时查看，最晚1-3天可在系统内查看)
          </div>
        </div>
      </div>
      <el-form
        v-if="activeName == 2"
        :model="formData"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
      >
        <div class="tab-content">
          <el-form-item label="银行类型" prop="name">
            <el-radio
              v-model="radios"
              label="1"
              @input="WithdrawalClick('1')"
              v-if="monynums > 0 || monynums == 0"
              >中金账户提现</el-radio
            >
            <el-radio
              v-model="radios"
              label="0"
              @input="WithdrawalClick('0')"
              v-if="monynum > 0 || monynum == 0"
              >中信账户提现</el-radio
            >
          </el-form-item>
        </div>
        <div class="tab-content" v-if="radios == 1">
          <el-form-item label="提现金额" prop="name">
            <el-input
              placeholder="请输入提现金额"
              v-model="formData.amount"
              @input="handleInput('formData', 'amount')"
              maxlength="12"
            ></el-input>
          </el-form-item>
          <el-form-item label="提现银行" prop="name" v-if="readlist.length > 0">
            <el-radio-group v-model="formData.radio" @change="typeChange">
              <el-radio
                v-for="(item, index) in readlist"
                :key="index"
                :label="item.cardId"
                >{{ item.bankName }}({{ item.bankCardNo.substr(-4) }})</el-radio
              >
              <el-radio :label="-1" v-if="activeName == 1">线下充值</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="提现银行" prop="name" v-else>
            <div class="flex">
              <div style="color: red">请先绑定银行卡</div>
              <el-button
                type="primary"
                size="mini"
                style="margin-left: 10px"
                @click="CardBinding"
                >绑卡</el-button
              >
            </div>
          </el-form-item>

          <div class="btn-wrap">
            <el-button type="primary" @click="submit(1)">发起提现</el-button>
          </div>
        </div>

        <div class="tab-content" v-if="radios == 0">
          <el-form-item label="提现金额" prop="name">
            <el-input
              placeholder="请输入提现金额"
              v-model="formData.amount"
              @input="handleInput('formData', 'amount')"
              maxlength="12"
            ></el-input>
          </el-form-item>
          <el-form-item label="提现银行" prop="name" v-if="readlist.length > 0">
            <el-radio-group v-model="formData.radio" @change="typeChange">
              <el-radio
                v-for="(item, index) in readlist"
                :key="index"
                :label="item.cardId"
                >{{ item.bankName }}({{ item.bankCardNo.substr(-4) }})</el-radio
              >
              <el-radio :label="-1" v-if="activeName == 1">线下充值</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="提现银行" prop="name" v-else>
            <div class="flex">
              <div style="color: red">请先绑定银行卡</div>
              <el-button
                type="primary"
                size="mini"
                style="margin-left: 10px"
                @click="CardBinding"
                >绑卡</el-button
              >
            </div>
          </el-form-item>
          <div class="btn-wrap">
            <el-button type="primary" @click="submit(0)">发起提现</el-button>
          </div>
        </div>
      </el-form>
    </div>
    <OfflinePay
      :isShow="offlinePayIsShow"
      @close="closeDialog('offlinePayIsShow')"
    />
    <InputPasswordDialog
      @submits="submits"
      :isShow="passWordIsShow"
      @func="getpassworddialog"
      @close="closeDialog('passWordIsShow')"
    />
  </div>
</template>

<script>
import { reqIsPayBond } from "@/api/hy";
import { bankCardList, getNowBalances, withdraw } from "@/api/public";
import md5 from "js-md5";
import Vue from "vue";
import * as types from "@/store/action-types";
import * as ESASignClient from "@/utils/signConfig/ESASignClient.js";
import {
  listBankCode,
  getAccountBalanceByParam,
  rechargeOnlineForZJ,
  WithDraw,
} from "@/api/Accountopening";
export default {
  data() {
    return {
      fullscreenLoading: false,
      options: [],
      bankID: "",
      input: "",
      radio: "1",
      radios: "1",
      monynum: null,
      monynums: null,
      config: {},
      readlist: [],
      btnndex: 1,
      table: {
        size: 10,
        total: 10,
        blance: 0,
        currentPage: 1,
        list: [
          {
            date: "2021-11-18 10:00:00",
            amount: "￥40000",
            mode: "银行卡充值",
            balance: "￥40000",
          },
          {
            date: "2021-11-18 10:00:00",
            amount: "￥40000",
            mode: "银行卡充值",
            balance: "￥40000",
          },
          {
            date: "2021-11-18 10:00:00",
            amount: "￥40000",
            mode: "银行卡充值",
            balance: "￥40000",
          },
          {
            date: "2021-11-18 10:00:00",
            amount: "￥40000",
            mode: "银行卡充值",
            balance: "￥40000",
          },
        ],
      },
      cashtable: {
        size: 10,
        total: 10,
        currentPage: 1,
        list: [
          {
            date: "2021-11-18 10:00:00",
            amount: "￥40000",
            mode: "银行卡充值",
            balance: "￥40000",
            name: "￥40000",
          },
          {
            date: "2021-11-18 10:00:00",
            amount: "￥40000",
            mode: "银行卡充值",
            balance: "￥40000",
            name: "￥40000",
          },
          {
            date: "2021-11-18 10:00:00",
            amount: "￥40000",
            mode: "银行卡充值",
            balance: "￥40000",
            name: "￥40000",
          },
          {
            date: "2021-11-18 10:00:00",
            amount: "￥40000",
            mode: "银行卡充值",
            balance: "￥40000",
            name: "￥40000",
          },
        ],
      },
      activeName: 1,
      showRechargeRecord: false,
      showWithdrawalRecord: false,
      radioIndex: "",
      formData: {},
      rules: {},
      offlinePayIsShow: false,
      passWordIsShow: false,
      breadcrumbDto: [
        { path: "/userIndex", title: "个人中心" },
        { path: "/", title: "资产管理" },
        { path: "/myAssets", title: "我的资产" },
      ],
      code: "",
      passwodds: "",
    };
  },
  components: {
    OfflinePay: () => import("./components/OfflinePay.vue"),
    InputPasswordDialog: () => import("@/components/InputPasswordDialog.vue"),
  },
  mounted() {
    // this.getBlance();

  
    this.AccountBalanceByParam();
  },
  methods: {
    CardBinding() {
      this.$router.push({
        path: "/manage",
      });
    },
    WithdrawalClick(val) {
      let config = {
        bankType: val,
        enterpriseId: Vue.ls.get(types.enterpriseId),
      };
      bankCardList(config).then((res) => {
        if (res.code == 0) {
          this.readlist = res.data;
        }
      });
    },
    AddRechargeOnlineForZJ() {
      if ((this.options.length = 0)) {
        this.$message({
          message: "请先绑定银行卡",
          type: "warning",
        });
        return false;
      }
      if (this.input == "") {
        this.$message({
          message: "充值金额不得为空",
          type: "warning",
        });
        return false;
      }
      if (this.input == 0) {
        this.$message({
          message: "充值金额不得为0",
          type: "warning",
        });
        return false;
      }
      this.fullscreenLoading = true;
      let config = {
        amount: this.input,
        bankID: this.bankID,
      };
      rechargeOnlineForZJ(config).then((res) => {
        console.log(res);
        if (res.code == 0) {
          this.fullscreenLoading = false;
          this.input = "";
          this.bankID = "";
          window.open(res.data);
        } else {
          this.$message.error(res.message);
          this.fullscreenLoading = false;
        }
      });
    },
    AccountBalanceByParam(val) {
      let config = {
        searchType: 0,
      };
      getAccountBalanceByParam(config).then((res) => {
        if (res.code == 0) {
          if (res.data.length > 0) {
            if (val == "1") {
              this.$message({
                message: "刷新成功",
                type: "success",
              });
            }
            let list = res.data;
            list.forEach((item) => {
              console.log(item);
              if (item.bankType == 0) {
                this.monynum = item.balance;
                this.radio = "0";
                this.radios = "0";
                return;
              } else if (item.bankType == 1) {
                this.monynums = item.balance;
                this.radio = "1";
                this.radios = "1";
                console.log(this.monynums, "------------");
              }
            });
               this.WithdrawalClick(this.radio);

          }
        }
      });
    },
    bankChange(e){
      if(e){
        this.getlistBankCode();
      }
    },
    getlistBankCode() {
      let config = {
        pageNum: 1,
        pageSize: 999,
      };
      listBankCode(config).then((res) => {
        console.log(res);
        if (res.code == 0) {
          this.options = res.data.list;
        }
      });
    },
    handleInput(item, tag) {
      this[item][tag] = this[item][tag].match(/^\d*(\.?\d{0,2})/g)[0] || "";
    },
    getpassworddialog(data) {
      this.code = data;
      if (this.code == 0) {
        this.formData = {};
        this.AccountBalanceByParam();
      }
    },
    recordbtn(index) {
      this.btnndex = index;
      if (index == 1) {
        this.showRechargeRecord = true;
      } else {
        this.showWithdrawalRecord = true;
      }
    },
    // getBlance() {
    //   getNowBalances().then((res) => {
    //     this.monynum = res.data;
    //     console.log(res);
    //   });
    // },
    handleSizeChange() {},
    handleCurrentChange() {},
    indexMethod(index) {
      return index + 1;
    },
    headerCellStyle() {
      return "background-color:#F7F7F7;color:#333333;font-size:14px;height:45px;text-align:center;font-weight: 400;";
    },
    cellStyle() {
      return "text-align:center;border-bottom:1px dashed #E5E5E5;text-align:center;";
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    typeChange(e) {
      this.radioIndex = e;
      if (e == -1) {
        this.offlinePayIsShow = true;
      }
    },
    closeDialog(tag) {
      this[tag] = false;
    },
    submits(data) {
      this.passwodds = data;
      let username = window.localStorage.getItem("userName");
      let config = {
        amount: this.formData.amount,
        cardId: this.formData.radio,
        companyName: username,
        password: md5(this.passwodds),
        bankType: this.formData.bankType,
      };
      withdraw(config).then((res) => {
        if (res.code == 0) {
          this.$message({
            type: "success",
            message: "提现申请已提交，到账时间以银行审核时间为准",
          });
          this.formData = {};
          this.radios = "";
          this.AccountBalanceByParam();
        } else {
          this.$message(res.message);
        }
      });
    },
    submit(val) {
      if (!this.beforhandle("personalCenter:myAssets:withdrawal:apply")) return;
      // this.config = config
      if (this.readlist.length == 0) {
        this.$message("您还未绑卡，请前往绑卡");
        return false;
      }
      if (!this.formData.amount) {
        this.$message("请输入充值金额");
        return false;
      }
      this.formData.bankType = val;
      if (!this.formData.radio) {
        this.$message("请选择提现银行");
        return false;
      }

      if (parseFloat(this.formData.amount) != this.formData.amount) {
        this.$message("请输入正确充值金额");
        return false;
      }

      // if (this.radioIndex == -1) {
      //   this.offlinePayIsShow = true;
      //   return;
      // }
      // this.passWordIsShow = true;
      let checkUkeyWhite = Vue.ls.get(types.checkUkeyWhite);
      if (checkUkeyWhite == 0) {
        ESASignClient.ESACheckIsKey(this.JsESACheckIsKeyk, null, false);
      } else {
        this.passWordIsShow = true;
      }
    },
    JsESACheckIsKeyk(status, msg, retpar, params) {
      let _this = this;
      console.log(status);
      if (status == 0) {
        this.passWordIsShow = true;
      } else {
        alert(msg);
        return false;
      }
    },
    nav(index) {
      let list = this.readlist;
      if (index == 2) {
        this.activeName = index;
        this.radioIndex = "";
        this.WithdrawalClick(this.radio);
        // if (list.length > 0) {
        // } else {
        //   this.$message("请绑定银行卡");
        // }
      } else {
        this.activeName = index;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.radiosO {
  margin-left: 10px;
  text-align: center;
  margin-top: 70px;
  margin-bottom: 20px;
}
::v-deep .el-table {
  .cell {
    white-space: nowrap;
  }
}
.el-table .el-table__cell {
  text-align: center;
}
.record-box {
  .record-table {
    width: 1092px;
    border: 1px solid #e5e5e5;
    border-bottom: 0;
    margin-bottom: 16px;
    .operation {
      color: #0458bf;
    }
  }
}
.btn {
  background-color: transparent;
  border: 0;
  font-size: 16px;
  width: 136px;
  height: 40px;
  line-height: 40px;
  border-radius: 20px;
  padding: 0;
}

.btn-active {
  background: linear-gradient(-45deg, #00a0ff, #0a65ff);
  box-shadow: 0px 5px 20px 0px rgba(143, 170, 249, 0.47);
  border-radius: 20px;
  color: #ffffff;
}
.myAssets {
  background: white;
  min-height: 500px;
  margin-bottom: 17px;
  margin-top: 20px;
  position: relative;
  &::before,
  &::after {
    display: table;
    content: "";
  }
}
.nav-title {
  position: absolute;
  top: -38px;
  left: 20px;
  font-size: 14px;
  color: #333333;
}
.flex {
  display: flex;
  align-items: center;
}
.top {
  display: flex;
  // align-items: center;
  justify-content: space-between;
  background: url("../../../assets/images/zichanbg.jpg") no-repeat center center;
  background-size: 100% 100%;
  height: 178px;
  .balance {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 20px;
    font-weight: bold;
    margin-left: 53px;
    i {
      font-size: 16px;
      color: #999999;
      margin-right: 7px;
    }
    .nums {
      font-size: 14px;
    }
    .num {
      color: #d0111a;
      font-size: 44px;
      min-width: 44px;
      span {
        font-size: 16px;
      }
    }
  }

  .right {
    margin-left: 43px;
    .el-button {
      display: block;
      margin: 10px;
    }
  }
}

.bottom {
  border: 1px solid #e5e5e5;
  margin-bottom: 50px;
  border-top: 0;
  margin: 18px 24px 20px 24px;
  padding-bottom: 52px;
  .nav {
    display: flex;
    margin-bottom: 10px;

    .nav-item {
      padding: 10px 20px;
      border-right: 1px solid #e5e5e5;
      border-bottom: 0;
      cursor: pointer;
      width: 50%;
      text-align: center;
      font-size: 20px;
      font-weight: 800;
      background-color: #f2f2f2;
      span {
        display: block;
        width: 33px;
        height: 3px;
        background: linear-gradient(90deg, #2079fb, #4cb2fc);
        border-radius: 2px;
        margin: 0 auto;
        margin-top: 18px;
      }
    }

    .active {
      border-top: 1px solid #cecece;
      border-right: 1px solid #cecece;
      color: #0294ff;
      background-color: #ffffff;
    }
  }

  .tab-content {
    width: 440px;
    margin: 0 auto;
    .el-input {
      width: 200px;
    }
    .el-radio {
      padding: 10px 0;
    }
    .btn-wrap {
      .el-button {
        width: 193px;
        height: 34px;
        line-height: 34px;
        background: linear-gradient(90deg, #2079fb, #4cb2fc);
        border-radius: 4px;
        padding: 0;
      }
    }
  }

  .btn-wrap {
    text-align: center;
  }
}
.zhi_img img {
  margin: 20px 0px;
  width: 100%;
}
</style>
